import cn from 'classnames';
import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { useForm, FieldPath, SubmitHandler } from 'react-hook-form';
import { FetchBaseQueryError } from '@services/df/baseApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider } from '@react-md/divider';
import { TextField } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { SignupForm } from '@types';
import { FeatureContent } from '@components/feature-content';
import { FormField, NonFieldErrors, NON_FIELD_ERROR_KEY } from '@components/form-field';
import { SubmitButton } from '@components/submit-button';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { selectAppSettings } from '@modules/app';
import { authActions, selectIsTokenObtaining } from '@modules/auth';
import { setApiErrors } from '@utils';
import { useValidation } from '@hooks';
import { useFormLabels } from '../hooks';
import { createSignupValidation } from '../form-validation';
import { AuthFormTitle } from '../components';
import { signUpId } from '../helpers';
import { SignUpFormId } from '../constants';
import { processEmailError } from '../../helpers';

import styles from './signup-form.module.scss';

interface Props {
    onSuccess?: (data?: SignupForm) => void;
}

export const SignupFormBody: React.FC<Props> = ({ onSuccess, children }) => {
    const dispatch = useAppDispatch();
    const isTokenObtaining = useAppSelector(selectIsTokenObtaining);
    const settings = useAppSelector(selectAppSettings);

    const isFirstNameRequired = useFeatureFlag(FF.SignUp.FirstNameRequired);
    const isLastNameRequired = useFeatureFlag(FF.SignUp.LastNameRequired);
    const isPhoneRequired = useFeatureFlag(FF.SignUp.PhoneRequired);
    const validationOptions = useMemo(
        () => ({
            isFirstNameRequired,
            isLastNameRequired,
            isPhoneRequired,
        }),
        [isFirstNameRequired, isLastNameRequired, isPhoneRequired],
    );

    const validationSchema = useValidation(createSignupValidation, validationOptions);
    const form = useForm<SignupForm>({
        defaultValues: {
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            phone: '',
            detail: '',
            // position: '',
            // company_title: '',
        },
        resolver: yupResolver(validationSchema),
    });

    const {
        control,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        clearErrors,
    } = form;

    const onSubmit: SubmitHandler<SignupForm> = form => {
        dispatch(
            authActions.signup({
                form,
                onSuccess,
                onError: error => {
                    const errorObject = processEmailError(error as FetchBaseQueryError['data']);
                    setApiErrors(setError, errorObject as Record<FieldPath<SignupForm>, string>);
                },
            }),
        );
    };

    const { t } = useTranslation();
    const labels = useFormLabels(t);
    return (
        <form id={SignUpFormId} onSubmit={handleSubmit(onSubmit)}>
            <div className={cn('fieldset-grid', 'base-paddings')}>
                <AuthFormTitle
                    title={t('titles.signUp', 'Sign up to {{company}}', {
                        company: settings.company_full_title || settings.company_title,
                    })}
                />

                <FormField
                    prefix={SignUpFormId}
                    name="email"
                    control={control}
                    component={TextField}
                    label={labels.email}
                    widgetProps={{
                        onChange: () => {
                            clearErrors(NON_FIELD_ERROR_KEY);
                        },
                    }}
                />

                <NonFieldErrors errors={errors} />

                <FormField
                    prefix={SignUpFormId}
                    name="password"
                    control={control}
                    component={TextField}
                    label={labels.password}
                    hint={
                        <Typography type="subtitle-2" component="span">
                            {t('errors.minLength', 'At least {{min}} characters long', { min: 10 })}
                        </Typography>
                    }
                    widgetProps={{
                        type: 'password',
                    }}
                />
            </div>

            <Divider />

            <div className={cn('base-paddings', 'no-top', 'fieldset-grid', 'fieldset-grid--columns', styles.columns)}>
                <FormField
                    prefix={SignUpFormId}
                    name="first_name"
                    control={control}
                    component={TextField}
                    label={labels.first_name}
                />
                <FormField
                    prefix={SignUpFormId}
                    name="last_name"
                    control={control}
                    component={TextField}
                    label={labels.last_name}
                />
                <FormField
                    prefix={SignUpFormId}
                    name="phone"
                    control={control}
                    component={TextField}
                    label={labels.phone}
                    className="fieldset-grid-cell--full"
                />
                {/*<FormField prefix={SignUpFormId}*/}
                {/*           name="position"*/}
                {/*           control={control}*/}
                {/*           component={TextField}*/}
                {/*           labels={labels}*/}
                {/*/>*/}
                {/*<FormField prefix={SignUpFormId}*/}
                {/*           name="company_title"*/}
                {/*           control={control}*/}
                {/*           component={TextField}*/}
                {/*           labels={labels}*/}
                {/*/>*/}

                <div className={cn('flex', 'fieldset-grid-cell--full')}>
                    <SubmitButton prefix={signUpId()} isSending={isTokenObtaining}>
                        <FeatureContent contentKey={signUpId('submit')} fallback={t('buttons.signUp', 'Sign up')} />
                    </SubmitButton>
                </div>
            </div>
        </form>
    );
};
